  import React from 'react';

import Layout from '../components/Layout';
import {HomeCard} from '../components/Card';
import TransformImage from '../assets/images/icon-creativeprocess.svg';
import SolutionsImage from '../assets/images/icon-cloudready.svg';
import PublicSectorImage from '../assets/images/icon-publicsector.svg';
import TalentImage from '../assets/images/icon-world.svg';

import LowCodeNoCodeImage from '../assets/images/icon-flow.svg';
import ModernizeImage from '../assets/images/icon-modernize.svg';


const herobg = '#1c2841', herofcolor='#efefef';

const IndexPage = () => (
  <Layout>
    <div id="main row" className='col-12' >
        <div className='row' style={{backgroundColor:herobg, color:herofcolor, padding:'2em 0'}}>
            <header className='col-9 inner' style={{margin:'.25em auto'}}>
              <h1 style={{margin:'auto .5em', letterSpacing:'.1em'}}>
                We accelerate innovation, supplement tech talent and transform business processes.
              </h1>
            
          </header>
      </div>
      <div className='row col-8 col-12-medium inner' style={{padding:'2em 0'}}>
        
        
            <HomeCard className='col-4 col-6-medium'  cardTitle="Transformation" cardImage={TransformImage} 
              cardLink='/transform-your-business' height='150px'>
              Innovate & transform business solutions, excel on your core competancy.

            </HomeCard>

        
            <HomeCard className='col-4 col-6-medium'  cardTitle="Business Solutions" cardImage={SolutionsImage} 
              cardLink='/business-solutions-on-the-cloud' height='150px'>
              Solve tomorrow's challenges by bringing customers and business processes together, today.

            </HomeCard>

        

        
            <HomeCard className='col-4 col-6-medium'  cardTitle="Public Sector Services" cardImage={PublicSectorImage} 
              cardLink='/public-sector-services' >
              Enforce compliance, Integrate processes. Analyze and share intelligence.

            </HomeCard>

            <HomeCard className='col-4 col-6-medium'  cardTitle="Talent Services" cardImage={TalentImage} 
              cardLink='/talent-services' >
               Need resources? We are resourceful.

            </HomeCard>

        
            <HomeCard className='col-4 col-6-medium'  cardTitle="Low Code / No Code" cardImage={LowCodeNoCodeImage} 
              cardLink='/lowcode-nocode' >
              Simplify complexities and realize faster time to business value.

            </HomeCard>

        

        
            <HomeCard className='col-4 col-6-medium'  cardTitle="Modernize" cardImage={ModernizeImage} 
              cardLink='/modernize-solutions' >
              Rigid Legacy Platforms, Technology End of Life? Modernize the Solutions.

            </HomeCard>

        
      </div>
    </div>
  </Layout>
);

export default IndexPage;
